@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.comoFuncionaSection {
  padding: 3em 0;

  .rowComoFunc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: $purple5;
    border-radius: 48px;
    padding: 2em;
    column-gap: 20px;
    row-gap: 20px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      border-radius: 24px;
      padding: 1em;
    }

    .boxComoFunc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  h2 {
    margin: 0;
    color: $purple2;
    text-align: center;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: 26px;
    }
  }

  img {
    margin: auto;
    width: 90%;

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  button {
    background: $orange;
    width: 100%;
    border: 0;
    height: 56px;
    border-radius: 8px;
    color: $white;
    font-size: 18px;
    font-weight: 600;
    margin-top: 2em;
    transition: 0.2s;

    &:hover {
      border: 1px solid $orange;
      background: transparent;
      color: $orange;
      transition: 0.2s;
    }
  }

  .lista {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;

    .numero {
      font-weight: bold;
      color: $orange;
      z-index: 2;
      position: relative;
      background: $purple5;
      font-size: 28px;
      border: 1px solid $orange;
      width: 60px;
      height: 60px;
      border-radius: 99px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px 15px 15px 0 !important;
      transition: 0.2s;
    }

    .numeroTitle {
      margin: 0;
      color: $gray1;
      font-size: 16px;
      font-weight: 700;
    }

    .numeroDescription {
      margin: 0;
      color: $gray1;
      font-size: 16px;
      font-weight: 400;

      b {
        color: $orange;
        text-decoration: underline;
      }
    }

    li {
      display: grid;
      grid-template-columns: 1fr 7fr;

      &:hover {
        .numero {
          background: $orange;
          color: $white;
          transition: 0.2s;
        }
      }

      @media (max-width: 800px) {
        padding: 1em 0;
      }

      div {
        margin: auto 0;
      }

      &::before {
        content: "";
        width: 1px;
        height: 92%;
        background-color: $orange;
        position: absolute;
        left: 30px;
        top: 15px;
        z-index: 0;

        @media (max-width: 800px) {
          height: 83%;
          top: 35px;
        }

        @media (max-width: 380px) {
          height: 80%;
        }
      }
    }
  }
}
