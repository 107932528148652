.swiper-pagination {
  position: relative !important;
  margin: 15px 0 0 0 !important;
}

.swiper-pagination-bullet {
  background: #4c38ab !important;
  width: 13px !important;
  height: 13px !important;
}
