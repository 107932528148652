@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.notFound {
  padding: 17em 0;
  text-align: center;

  h1 {
    color: $purple4;
    margin: 0;
    font-size: 50px;
    text-align: center;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    color: $gray1;
  }

  button {
    background: $orange;
    width: 500px;
    border: 0;
    height: 56px;
    border-radius: 8px;
    color: $white;
    font-size: 18px;
    font-weight: 600;
    margin-top: 2em;
    transition: 0.2s;

    @media (max-width: 800px) {
      width: 100%;
    }

    @media (max-width: 500px) {
      margin-top: 0;
    }

    &:hover {
      border: 1px solid $orange;
      background: transparent;
      color: $orange;
      transition: 0.2s;
    }
  }
}
