@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

header {
  background-color: $purple1;
  padding: 1.5em 0;
  transition: 0.2s;

  @media (max-width: 800px) {
    padding: 1em 0;
  }

  .MenuMobileIcon {
    display: none;

    img {
      width: 50px;

      @media (max-width: 800px) {
        width: 40px;
      }
    }

    @media (max-width: 1300px) {
      display: flex;
    }
  }

  .rowHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .imgHeader {
      width: 235px;
      transition: 0.2s;

      @media (max-width: 1300px) {
        width: 50px;
      }

      @media (max-width: 800px) {
        width: 35px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      margin: 0;
      column-gap: 42px;

      li {
        a {
          text-decoration: none;
          font-size: 14px;
          color: $white;
          font-weight: 400;
          transition: 0.2s;

          &:hover {
            color: $orange;
            transition: 0.2s;
          }
        }
      }
    }

    .buttonHeader {
      display: flex;
      column-gap: 15px;

      button {
        cursor: pointer;
      }

      .buttonHeaderParceiro {
        border: 2px solid $white;
        background: transparent;
        color: $white;
        font-weight: 700;
        font-size: 16px;
        border-radius: 8px;
        padding: 8px 20px;
        transition: 0.2s;

        &:hover {
          border: 2px solid $orange;
          color: $orange;
          transition: 0.2s;
        }
      }

      .buttonHeaderLogin {
        background: transparent;
        color: $white;
        border: 0;
        font-weight: 700;
        font-size: 16px;
        border-radius: 8px;
        padding: 8px 20px;
        transition: 0.2s;

        &:hover {
          color: $orange;
          transition: 0.2s;
        }
      }
    }

    @media (max-width: 1300px) {
      .boxMenuDesktop,
      .buttonHeader {
        display: none;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
}

.overlay.show {
  opacity: 1;
  visibility: visible;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  height: 100%;
  background-color: $purple1;
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 2em;

  .headerSidebar {
    display: flex;
    justify-content: space-between;

    img {
      width: 40px;
    }
  }

  .bodySidebar {
    button {
      background: $orange;
      width: 100%;
      border: 0;
      height: 56px;
      border-radius: 8px;
      color: $white;
      font-size: 18px;
      font-weight: 600;
      margin: 2em 0 1em 0;
      transition: 0.2s;

      &:hover {
        border: 1px solid $orange;
        background: transparent;
        color: $orange;
        transition: 0.2s;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    li {
      padding: 1em 0;
      border-bottom: 1px solid $purple2;

      a {
        text-decoration: none;
        font-size: 18px;
        color: $white;
        font-weight: 600;
        transition: 0.2s;

        &:hover {
          color: $orange;
        }
      }
    }
  }

  &.open {
    right: 0;
  }
}

.fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: 0.2s;
  padding: 1em 0;

  .imgHeader {
    width: 170px !important;
    transition: 0.2s;
  }
}
