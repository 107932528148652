@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.footerSection {
  background: $purple4;
  padding: 2em 0 0 0;

  .rowFooter {
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
    }
  }

  .boxFooter {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    column-gap: 20px;
    list-style: none;

    @media (max-width: 500px) {
      flex-direction: column;
      row-gap: 20px;
    }

    a {
      text-decoration: none;
    }

    li {
      color: $white;
      font-style: italic;
      font-size: 16px;
      font-weight: 400;
      transition: 0.2s;

      &:hover {
        color: $orange;
        transition: 0.2s;
      }
    }
  }

  .imgFooter {
    width: 200px;
  }

  .imgFooterSocial {
    width: 30px;
    margin: 0 5px;
  }
}

.rowCopy {
  background: $purple3;
  padding: 8px 0;
  margin-top: 2em;

  p {
    margin: 0;
    text-align: center;
    color: $white;
    font-size: 12px;
  }
}
