@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.influencerSection {
  background: #f0edfc;
  padding: 3em 0;

  h2 {
    margin: 0 0 0.5em 0;
    color: $purple4;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: 26px;
    }
  }

  p {
    color: $gray1;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  button {
    background: $orange;
    width: 500px;
    border: 0;
    height: 56px;
    border-radius: 8px;
    color: $white;
    font-size: 18px;
    font-weight: 600;
    margin-top: 1em;

    &:hover {
      border: 1px solid $orange;
      background: transparent;
      color: $orange;
      transition: 0.2s;
    }

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  img {
    width: 100%;
  }

  .rowInfluencer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }

  .boxInfluencer {
    margin: auto 0;
  }
}
