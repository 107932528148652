@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.container {
  position: relative;
}

.imgFaqApas2 {
  position: absolute;
  width: 65px;
  top: 75px;
  right: 0;

  @media (max-width: 1300px) {
    display: none;
  }
}

.imgFaqApas1 {
  position: absolute;
  width: 65px;
  bottom: 4px;

  @media (max-width: 1300px) {
    display: none;
  }
}

.faqSection {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3em 0;
  overflow: hidden;

  h2 {
    margin: 0 0 1em 0;
    color: $purple2;
    text-align: center;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: 26px;
    }
  }

  .boxFaq {
    width: 25%;
    background: $white;
    border-radius: 16px;
    padding: 1em;
    transition: 0.2s;
    box-sizing: border-box;
    min-height: max-content;

    @media (max-width: 800px) {
      width: auto;
      height: 400px !important;
    }

    @media (max-width: 500px) {
      height: 480px !important;
    }

    @media (max-width: 380px) {
      height: 520px !important;
    }

    @media (max-width: 350px) {
      height: 590px !important;
    }

    &:hover {
      opacity: 0.7;
      transform: translateY(-10px);
      transition: 0.2s;

      @media (max-width: 800px) {
        transform: translateY(0);
      }
    }

    .faqHeader {
      display: flex;
      align-items: center;
      column-gap: 15px;

      img {
        border-radius: 99px;
        width: 65px;
      }

      h3 {
        margin: 0;
        color: $gray1;
        font-size: 18px;
        font-weight: 700;
      }

      h4 {
        margin: 0;
        color: $gray1;
        font-size: 16px;
        font-weight: 400;
      }
    }

    p {
      color: $gray2;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .column2Box2Faq {
    margin-top: 4em;

    @media (max-width: 1300px) {
      margin-top: 0;
    }
  }

  .column2Box1Faq {
    height: 575px;

    @media (max-width: 1300px) {
      height: unset;
    }
  }

  .rowFaq {
    display: flex;
    column-gap: 20px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .sliderContainerFaq {
    display: none;

    @media (max-width: 800px) {
      display: block;
    }
  }
}
