// Color
$purple1: #4c38ab;
$purple2: #644ed0;
$purple3: #4229a0;
$purple4: #644fd0;
$purple5: #f2f5f8;
$gray1: #373737;
$gray2: #636363;
$gray3: #d6d6d6;
$gray4: #f0edfc;
$orange: #ff5b12;
$white: #fff;
$white2: #fff;
