@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.dadosSection {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3em 0;
  overflow: hidden;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

.row3Dados img {
  animation: float 3s ease-in-out infinite;
}

.row1Dados {
  display: flex;
  column-gap: 20px;

  @media (max-width: 800px) {
    display: none;
  }

  .column2Box1Dados {
    width: 15%;
    height: 200px;

    @media (max-width: 1300px) {
      height: auto;
      width: auto;
    }
  }

  .column2Box2Dados {
    width: 20%;
    margin-top: 2em;
    height: 270px;

    @media (max-width: 1300px) {
      margin-top: 0;
      height: auto;
      width: auto;
    }
  }

  .column2Box3Dados {
    width: 26%;
    margin-top: 6em;

    @media (max-width: 1300px) {
      margin-top: 0;
      width: auto;
    }
  }
}

.sliderContainerDados {
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
}

.box1Dados {
  background: $white;
  border-radius: 16px;
  padding: 1em;
  box-sizing: border-box;
  min-height: max-content;
  opacity: 1;
  transition: 0.2s;

  @media (max-width: 800px) {
    height: 200px;
  }

  &:hover {
    opacity: 0.7;
    transform: translateY(-10px);
    transition: 0.2s;

    @media (max-width: 800px) {
      transform: translateY(0);
    }
  }

  img {
    width: 50px;
    object-fit: contain;
    height: 50px;
    margin-bottom: 1em;
  }

  h3 {
    margin: 0;
    color: $gray1;
    font-size: 25px;
    font-weight: 700;

    @media (max-width: 1300px) {
      font-size: 20px;
    }
  }
}

.row2Dados {
  .box2Dados {
    text-align: center;

    img {
      width: 45%;
      margin-top: -2em;
      z-index: 2;
      position: relative;

      @media (max-width: 800px) {
        margin-top: -2em;
        width: 100%;
      }

      @media (max-width: 340px) {
        margin-top: -1em;
      }
    }
  }
}

.row3Dados {
  position: relative;

  @media (max-width: 1300px) {
    display: none;
  }

  .icon1Row3 {
    position: absolute;
    width: 18%;
    top: -415px;
    left: -21px;
  }

  .icon2Row3 {
    position: absolute;
    width: 6%;
    top: -260px;
    left: 95px;
  }

  .icon3Row3 {
    position: absolute;
    width: 20%;
    top: -305px;
    left: 189px;
  }

  .icon4Row3 {
    position: absolute;
    width: 6%;
    top: -65px;
    left: 300px;
    z-index: 2;
  }

  .icon5Row3 {
    position: absolute;
    width: 6%;
    right: 335px;
    top: -265px;
    z-index: 2;
  }

  .icon6Row3 {
    position: absolute;
    width: 23%;
    right: 145px;
    top: -157px;
    z-index: 2;
  }

  .icon7Row3 {
    position: absolute;
    width: 23%;
    right: -25px;
    top: -310px;
  }
}
