@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.vantagensSection {
  padding: 0 0 3em 0;

  h2 {
    margin: 0 0 1em 0;
    color: $purple2;
    text-align: center;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: 26px;
    }
  }

  img {
    width: 60px;
    object-fit: contain;
    height: 60px;
  }

  h3 {
    margin: 10px 0;
    color: $orange;
    font-size: 18px;
    font-weight: 700;
  }

  p {
    color: $gray1;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  button {
    background: $orange;
    width: 500px;
    border: 0;
    height: 56px;
    border-radius: 8px;
    color: $white;
    font-size: 18px;
    font-weight: 600;
    margin-top: 2em;

    &:hover {
      border: 1px solid $orange;
      background: transparent;
      color: $orange;
      transition: 0.2s;
    }

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  .rowVantagens {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  .boxVantagensButton {
    text-align: center;
  }

  .boxVantagens {
    text-align: center;
    opacity: 1;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
      transform: translateY(-10px);
      transition: 0.2s;
    }
  }
}
