@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.bannerSection {
  background: $purple2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;

  @media (max-width: 1300px) {
    background-repeat: unset;
    background-position-x: center;
    padding: 3em 0;
  }

  iframe {
    width: 100%;
    box-sizing: border-box;
    border-radius: 16px;
  }

  .rowBanner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    row-gap: 20px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    .boxBanner {
      display: flex;
      flex-direction: column;
      height: 550px;
      row-gap: 20px;
      justify-content: center;

      @media (max-width: 1300px) {
        height: auto;
      }

      h1 {
        margin: 0;
        color: $white;
        font-weight: 700;
        font-size: 35px;

        @media (max-width: 800px) {
          font-size: 26px;
        }
      }

      p {
        margin: 0;
        color: $white;
        font-size: 18px;
      }

      span {
        font-weight: 700;
        border-bottom: 2px solid $orange;
      }

      button {
        background: $orange;
        width: 500px;
        border: 0;
        height: 56px;
        border-radius: 8px;
        color: $white;
        font-size: 18px;
        font-weight: 600;
        margin-top: 2em;
        transition: 0.2s;

        @media (max-width: 800px) {
          width: 100%;
        }

        @media (max-width: 500px) {
          margin-top: 0;
        }

        &:hover {
          border: 1px solid $orange;
          background: transparent;
          color: $orange;
          transition: 0.2s;
        }
      }

      .imgHeader {
        width: 35px;

        @media (max-width: 1300px) {
          display: none;
        }
      }
    }
  }
}
