@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.SliderProdutoSection {
  padding: 3em 0 1em 0;

  @media (max-width: 800px) {
    padding: 3em 0 0 0;
  }

  h2 {
    margin: 0 0 1em 0;
    color: $purple2;
    text-align: center;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: 26px;
    }
  }

  .imgSlider {
    object-fit: contain;
    width: 100%;
    height: 60px;
    object-position: center;
    transition: 0.2s;
    filter: grayscale(1);

    &:hover {
      filter: grayscale(0);
      transition: 0.2s;
    }
  }
}

.prevArrow,
.nextArrow {
  height: auto;

  &:before {
    content: none !important;
  }

  svg {
    font-size: 30px;
  }

  path {
    fill: $purple2;
  }
}
