@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.tabSection {
  padding: 2em 0 3em 0;
}

.tabHeaders {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.tabHeader {
  padding: 3em 1em;
  cursor: pointer;
  background: $white;
  border: 2px solid $purple2;
  border-radius: 16px;
  width: 100%;
  transition: 0.2s;

  @media (max-width: 800px) {
    margin: 10px 0;
  }

  @media (max-width: 500px) {
    padding: 2em 1em;
  }

  img {
    width: 75%;

    @media (max-width: 500px) {
      width: 60%;
    }
  }

  &.active {
    background: $purple2;

    img {
      filter: brightness(0) invert(1);
    }
  }

  &:hover {
    background: $purple2;
    transition: 0.2s;

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.tabContent {
  padding: 3em 2em 2em 2em;
  border-radius: 16px;
  border: 2px solid $gray3;
  margin-top: 20px;

  @media (min-width: 800px) {
    margin-top: -30px;
  }

  @media (max-width: 800px) {
    padding: 3em 1em 1em 1em;
    margin-top: -40px;
    margin-bottom: 10px;
  }

  label {
    font-size: 18px;
    font-weight: 700;
    color: $purple2;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    color: $gray1;
    margin: 0 0 10px 0;
  }
}
