@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.bannerSection {
  background: $purple2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;

  @media (max-width: 1300px) {
    background-repeat: unset;
    background-position-x: center;
    padding: 3em 0;
  }

  .rowBanner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    row-gap: 20px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    .boxBanner {
      display: flex;
      flex-direction: column;
      height: 550px;
      row-gap: 20px;
      justify-content: center;

      @media (max-width: 1300px) {
        height: auto;
      }

      h1 {
        margin: 0;
        color: $white;
        font-weight: 700;
        font-size: 35px;

        @media (max-width: 800px) {
          font-size: 26px;
        }
      }

      p {
        margin: 0;
        color: $white;
        font-size: 18px;
      }

      span {
        font-weight: 700;
        border-bottom: 2px solid $orange;
      }

      button {
        background: $orange;
        width: 100%;
        border: 0;
        height: 56px;
        border-radius: 8px;
        color: $white;
        font-size: 18px;
        font-weight: 600;
        transition: 0.2s;

        @media (max-width: 800px) {
          width: 100%;
        }

        @media (max-width: 500px) {
          margin-top: 0;
        }

        &:hover {
          border: 1px solid $orange;
          background: transparent;
          color: $orange;
          transition: 0.2s;
        }
      }

      .imgHeader {
        width: 35px;

        @media (max-width: 1300px) {
          display: none;
        }
      }
    }

    .duoInputRow {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 10px;

      @media (max-width: 500px) {
        flex-direction: column;
        row-gap: 10px;
      }
    }

    .oneInputRow {
      margin-bottom: 10px;
    }

    form {
      text-align: center;
    }

    .errorMessage {
      padding: 10px 0;
    }

    .boxInputForm {
      position: relative;
      display: flex;
      align-items: center;
      background: $white;
      border-radius: 16px;
      width: 100%;

      img {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 12px;
        object-fit: contain;
      }

      input {
        height: 56px;
        background: transparent;
        border: 0;
        padding: 0 0 0 40px;
        font-size: 16px;
        font-weight: 600;
        color: $gray1;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
          color: $gray2;
        }
      }
    }
  }
}
