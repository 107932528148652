@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/global";

.publicoSection {
  padding: 3em 0;
  overflow: hidden;

  h2 {
    margin: 0 0 1em 0;
    color: $purple4;
    text-align: center;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: 26px;
    }
  }

  h3 {
    margin: 0 0 0.5em 0;
    font-size: 28px;
    font-weight: 700;
    color: $gray1;

    @media (max-width: 1300px) {
      font-size: 20px;
    }
  }

  a {
    text-decoration: none !important;
  }

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: $gray1;
  }

  img {
    width: 40px;
    margin: 0 0 0 auto;
  }

  .rowPublico {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .sliderContainerPublico {
    display: none;

    @media (max-width: 800px) {
      display: block;
    }
  }

  .boxPublico {
    display: flex !important;
    flex-direction: column;
    border: 1px solid $gray1;
    border-radius: 16px;
    padding: 1em;
    justify-content: space-between;
    transition: 0.2s;
    height: 100%;
    box-sizing: border-box;
    min-height: max-content;
    cursor: pointer;

    @media (max-width: 800px) {
      height: 280px;
      margin: 0.5em;
    }

    &:hover {
      background: $purple4;
      border: 1px solid $white;
      transition: 0.2s;

      h3 {
        color: $white;
      }

      p {
        color: $white;
      }

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
